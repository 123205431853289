{
  "_from": "svg2geojson@0.7.1",
  "_id": "svg2geojson@0.7.1",
  "_inBundle": false,
  "_integrity": "sha512-esdJ+qJ7NcsbFnxT27xIFjb/CDKvG+Wb6JiviT3ojvPgbEaB+kWcwaprJQ6Xtv7KXlaTycN8qnJImWI7VHtdew==",
  "_location": "/svg2geojson",
  "_phantomChildren": {},
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "svg2geojson@0.7.1",
    "name": "svg2geojson",
    "escapedName": "svg2geojson",
    "rawSpec": "0.7.1",
    "saveSpec": null,
    "fetchSpec": "0.7.1"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/svg2geojson/-/svg2geojson-0.7.1.tgz",
  "_shasum": "2ef1e4f93999723f175eaf0621acccb1a7ac827f",
  "_spec": "svg2geojson@0.7.1",
  "_where": "/home/voterpur/public_html/coop/electric-contacts",
  "author": {
    "name": "Gavin Kistner"
  },
  "bin": {
    "svg2geojson": "bin/svg2geojson"
  },
  "bugs": {
    "url": "https://github.com/Phrogz/svg2geojson/issues"
  },
  "bundleDependencies": false,
  "dependencies": {
    "minimist": "^1.2.0",
    "neatjson": "^0.8.3",
    "svg-path-parser": "^1.0.1",
    "svg-path-to-polygons": "^0.1.0",
    "vmath": "^1.3.1",
    "xml2js": "^0.4.17"
  },
  "deprecated": false,
  "description": "Converts an SVG file with added geo-referencing tags into one or more GeoJSON files.",
  "devDependencies": {},
  "directories": {
    "lib": "lib",
    "test": "test"
  },
  "homepage": "https://github.com/Phrogz/svg2geojson#readme",
  "keywords": [
    "svg",
    "geojson"
  ],
  "license": "MIT",
  "main": "lib/svg2geojson.js",
  "name": "svg2geojson",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/Phrogz/svg2geojson.git"
  },
  "scripts": {
    "test": "echo \"Error: no test specified\" && exit 1"
  },
  "version": "0.7.1"
}
